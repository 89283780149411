// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Hero from "../components/plateforme-saas/hero"
import LogoSection from "../components/plateforme-saas/logo-section"
import CardsSectionDesktop from "../components/plateforme-saas/cards-section-desktop"
import Impact from "../components/plateforme-saas/impact"
import Bridgestone from "../components/plateforme-saas/bridgestone"
import GetSupport from "../components/plateforme-saas/get-support"
import SoftwareAndMap from "../components/plateforme-saas/software-and-map"
import CardsSectionMobile from "../components/plateforme-saas/cards-section-mobile"

const PlateformeSaaS = ({ data, pageContext }) => {
  const { allContentfulFooter } = data
  const langKey = pageContext.langKey

  return (
    <>
      <Helmet>
        <title>Unique Amazon Retail Media Analytics Software - Seelk</title>
        <meta
          name="description"
          content="The most complete tool to track your Retail Media data and optimize your growth and ROI, and also your conversion rate, visibility, SEO and market share."
        />
        <meta name="format-detection" content="telephone=no" />
      </Helmet>
      <Layout
        isDarkMode
        langKey={langKey}
        allContentfulFooter={allContentfulFooter}
      >
        <Hero langKey={langKey} />
        <LogoSection langKey={langKey} />
        <CardsSectionDesktop langKey={langKey} />
        <CardsSectionMobile langKey={langKey} />
        <SoftwareAndMap langKey={langKey} />
        <Impact langKey={langKey} />
        <Bridgestone langKey={langKey} />
        <GetSupport langKey={langKey} />
      </Layout>
    </>
  )
}

export default PlateformeSaaS

export const data = graphql`
  query AmazonAnalyticsSoftwareQuery($langKey: String!) {
    allContentfulFooter(
      filter: { node_locale: { eq: $langKey }, agencyServices: { ne: null } }
    ) {
      edges {
        node {
          node_locale
          seelkOffices {
            officeCity
            officeAddress
            officeTelephoneNumber
          }
          saasForAmazon
          agencyServices
          aboutSeelk
          copyrights
          contact
          connect
        }
      }
    }
  }
`
